import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const GrainsPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Grains"
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-primary">
      <nav>
        <h3>Fundamentals</h3>
        <ul>
          <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
          <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
          <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
          <li>
              <Link to={'/fundamentals/what-we-grow'} activeClassName="active--no-border">What We Grow</Link>
              <ul>
                <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
              </ul>   
          </li>
          <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
        </ul> 
      </nav>
      <section>
        <p>Howell Farms grows a variety of grains, including corn, soybeans, wheat and barley.</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "grains-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default GrainsPage
